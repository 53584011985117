import { ref, onMounted } from '@nuxtjs/composition-api';
import useApi from '~/composables/useApi';
import latestProductsQuery from './latestProductsQuery';

export const useLatestProducts = (id) => {
  const { query } = useApi();
  const latestProducts = ref(null);

  interface ResponseData {
    data?: {
      category?: {
        products?: any[];
        name?: string;
      }
    };
  }

  onMounted(() => {
    query(latestProductsQuery, { id: id })
      .then((data: ResponseData) => {
        latestProducts.value = data?.data?.category?.products || [];
        return data;
      })
      .catch(() => {
        latestProducts.value = false;
      });
  });

  return {
    latestProducts,
  };
};

export default useLatestProducts;