//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { useProduct } from "~/composables";
import { useUser } from "~/modules/customer/composables/useUser";

export default {
  name: "BestSellersCard",
  props: {
    item: {
      type: Object,
      required: false,
      default: {},
    },
    categoryName: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup() {
    const { getProductPath } = useProduct();
    const { isAuthenticated } = useUser();
    return {
      getProductPath,
      isAuthenticated,
    };
  },
};
