//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref } from "@nuxtjs/composition-api";
import useLatestProducts from "./latestProducts/useLatestProducts";
import VueSlickCarousel from "vue-slick-carousel";
import BestSellersCard from "../BestSellersCard.vue";
import { Icon } from "@iconify/vue2";

export default defineComponent({
  name: "LatestSLider",
  props: ["blok"],
  components: {
    VueSlickCarousel,
    BestSellersCard,
    Icon,
  },
  setup(props) {
    const { latestProducts } = useLatestProducts(props.blok?.category_id);
    const settings = ref({
      infinite: true,
      arrows: true,
      dots: false,
      slidesToShow: 4,
      speed: 500,
      rows: 1,
      slidesPerRow: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
          },
        },
      ],
    });

    return {
      latestProducts,
      settings,
    };
  },
});
